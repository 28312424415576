<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Agregar Dispositivo</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Nombre*"
                  required
                  v-model="deviceNew.Name"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Imei*"
                  hint="Identificador del Dispositivo"
                  v-model="deviceNew.Uniqueid"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  label="Telefono"
                  v-model="deviceNew.Phone"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                  <v-select :items="iconList"  item-value="image" label="Icon" v-model="deviceNew.Icon">
                        <template v-slot:selection="{ item }">
                            <img :src="item.image" height="30px" />
                        </template>
                        <template v-slot:item="{ item }">
                            <img :src="item.image" height="30px" />
                        </template>
                    </v-select>
              </v-col>
              <v-col
                cols="12"
              >
               <v-select 
                    :items="types" 
                    item-text="name"
                    item-value="id"
                    label="Types*" 
                    v-model="deviceNew.Devicetypeid"
                    required
                    ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>* Indicador de Requeridos</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveDev"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props:{
      types: {
            type:Array
            ,default: () =>  []
        }
        ,iconList:{
            type:Array
            ,default:()=>[]
        }
    },
    data: ()=>({
        dialog:false,
        deviceNew:{
          Name:null,
          Uniqueid:null,
          Icon:null,
          Devicetypeid:0,
          Phone:null,
        }
    }),
    methods:{
      ...mapActions(['addDevice']),
      saveDev(){
          this.addDevice(this.deviceNew).then((res)=>{
            //console.log(res);
            if(res.status == 200)
              this.showSnackbar({
                showing:true,
                message: `Created device ${res.data.data.name}!`,
                color:'success'
              });
            this.dialog = false;
          }) .catch((err)=>{
                console.log(err);
            });
      },
    }
}
</script>

<style>

</style>