var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Agregar Dispositivo")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre*","required":""},model:{value:(_vm.deviceNew.Name),callback:function ($$v) {_vm.$set(_vm.deviceNew, "Name", $$v)},expression:"deviceNew.Name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Imei*","hint":"Identificador del Dispositivo","required":""},model:{value:(_vm.deviceNew.Uniqueid),callback:function ($$v) {_vm.$set(_vm.deviceNew, "Uniqueid", $$v)},expression:"deviceNew.Uniqueid"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Telefono","required":""},model:{value:(_vm.deviceNew.Phone),callback:function ($$v) {_vm.$set(_vm.deviceNew, "Phone", $$v)},expression:"deviceNew.Phone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.iconList,"item-value":"image","label":"Icon"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.image,"height":"30px"}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.image,"height":"30px"}})]}}]),model:{value:(_vm.deviceNew.Icon),callback:function ($$v) {_vm.$set(_vm.deviceNew, "Icon", $$v)},expression:"deviceNew.Icon"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.types,"item-text":"name","item-value":"id","label":"Types*","required":""},model:{value:(_vm.deviceNew.Devicetypeid),callback:function ($$v) {_vm.$set(_vm.deviceNew, "Devicetypeid", $$v)},expression:"deviceNew.Devicetypeid"}})],1)],1)],1),_c('small',[_vm._v("* Indicador de Requeridos")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveDev}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }